import axios from 'axios';
import authHeader from './auth-header';
import authHeaderFile from './auth-header-file';

import { URL_API } from './Commons';

class EstudoService {

  async getAll() {
    return await axios.get(URL_API + 'estudos', { headers: authHeader() });
  }

  getOne(id) {
    return axios.get(URL_API + 'estudo/' + id, { headers: authHeader() });
  }

  insert(estudo) {
    return axios.post(URL_API + 'estudo', estudo, { headers: authHeader() });
  }

  update(id, estudo) {
    return axios.put(URL_API + 'estudo/' + id, estudo, { headers: authHeader() });
  }

  delete(id) {
    return axios.delete(URL_API + 'estudo/' + id, { headers: authHeader() });
  }

  sendFileImg(id, formData) {
    return axios.post(URL_API + 'estudoupimagem/' + id, formData, { headers: authHeaderFile() });
  }
  sendFilePdf(id, formData) {
    return axios.post(URL_API + 'estudouppdf/' + id, formData, { headers: authHeaderFile() });
  }

}

export default new EstudoService();