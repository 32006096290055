<template>
  <div>    
      <div class="col-12 mb-3">
        <label for="categoria" class="form-label">Categoria</label>
        <select
          class="form-select"
          @change="formInclude.categoria_id = $event.target.value"
          v-model="formInclude.categoria_id"
        >
          <option>Selecionar</option>
          <option :value="item.id" v-for="item in categoriaList" :key="item.id">{{item.titulo}}</option>
        </select>
      </div>
      <div class="col-12 mb-3">
        <label for="titulo" class="form-label">Título</label>
        <input
          type="text"
          class="form-control"
          v-model="formInclude.titulo"
          aria-describedby="name_help"
          placeholder="Título do estudo"
        />
      </div>
      <div class="col-12 mb-3">
        <label for="subtitulo" class="form-label">Sub Título</label>
        <input
          type="text"
          class="form-control"
          v-model="formInclude.subtitulo"
          aria-describedby="subtitulo"
          placeholder="Sub Título do estudo"
        />
      </div>
      <div class="col-12 mb-3">
        <label for="conteudo" class="form-label">Conteúdo</label>        
        <TiptapVr v-model="formInclude.conteudo"> </TiptapVr>
      </div>
      <div class="col-12 mb-3">
        <label for="lang_input" class="form-label">{{
          $t("status")
        }}</label>
        <select
          class="form-select"
          @change="formInclude.status = $event.target.value"
          v-model="formInclude.status"
        >
          <option value="1">Ativo</option>
          <option value="0">Inativo</option>
        </select>
      </div>
      
      <div class="d-grid gap-2">
        <button
          class="btn btn-success bg-success bg-gradient col-6 mx-auto"
          type="button"
        @click="sendNew"
        >
          {{ $t("enviar") }} {{ $t("newone") }}
        </button>
      </div>
    
  </div>
</template>

<script>
import TiptapVr from "../../../../../components/TipTapVr";
export default {
  props: ["actionSend", "categoriaList"],
  data: () => ({
    formInclude: {},
  }),
  mounted() {},
  components: {
    TiptapVr,
  },
  methods: {
    sendNew: function (event) {
      event.preventDefault();
      this.actionSend(this.formInclude);
    },
  },
};
</script>

<style>
</style>